var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column justify-content-center" },
    [
      _c("div", { staticClass: "card-header-small mb-2" }, [
        _vm._v("Active Accounts Receivable"),
      ]),
      _c(
        "div",
        { staticClass: "d-flex justify-content-around align-items-center" },
        [
          _c(
            "b-overlay",
            { attrs: { variant: "transparent", show: _vm.totalARLoading } },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column total-active-box" },
                [
                  _c("div", { staticClass: "data-label" }, [
                    _vm._v("Total active"),
                  ]),
                  _c("div", { staticClass: "stat-text" }, [
                    _vm._v(_vm._s(_vm.totalActiveARCount || 0)),
                  ]),
                  _c("div", { staticClass: "value-data" }, [
                    _vm._v(
                      " Value: " +
                        _vm._s(_vm.formatDollars(_vm.totalActiveARValue)) +
                        " "
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "b-overlay",
            { attrs: { variant: "transparent", show: _vm.totalARLoading } },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column justify-content-center" },
                  [
                    _c("div", { staticClass: "data-label" }, [
                      _vm._v("Insurance A/R"),
                    ]),
                    _c("div", { staticClass: "stat-text" }, [
                      _vm._v(_vm._s(_vm.insuranceARCount || 0)),
                    ]),
                    _c("div", { staticClass: "value-data" }, [
                      _vm._v(
                        " Value: " +
                          _vm._s(_vm.formatDollars(_vm.insuranceARValue)) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "chart-container",
                    staticStyle: { width: "90px" },
                  },
                  [
                    _c("progress-circle-chart", {
                      attrs: {
                        percent: _vm.percentInsAR,
                        styles: { height: "50px", position: "relative" },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-overlay",
            { attrs: { variant: "transparent", show: _vm.totalARLoading } },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column justify-content-center" },
                  [
                    _c("div", { staticClass: "data-label" }, [
                      _vm._v("Patient A/R"),
                    ]),
                    _c("div", { staticClass: "stat-text" }, [
                      _vm._v(_vm._s(_vm.patientARCount || 0)),
                    ]),
                    _c("div", { staticClass: "value-data" }, [
                      _vm._v(
                        " Value: " +
                          _vm._s(_vm.formatDollars(_vm.patientARValue)) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "chart-container",
                    staticStyle: { width: "90px" },
                  },
                  [
                    _c("progress-circle-chart", {
                      attrs: {
                        percent: _vm.percentPatientAR,
                        styles: { height: "50px", position: "relative" },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }