var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "job-overlay",
    {
      attrs: { job: _vm.job, loading: _vm.billingSummaryLoading },
      on: { finished: _vm.onFinished },
    },
    [
      _c(
        "div",
        { staticClass: "billing-summary-card shadow mb-4 p-4" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex flex-column justify-content",
                  attrs: { cols: "4" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "data-label",
                      staticStyle: { "font-size": "14px" },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _vm._v(" Total "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.providerOrPractice)),
                            ]),
                            _vm._v(" billed value "),
                          ]),
                          _vm.doAllowPracticeTotals
                            ? _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button-group",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "ml-2 avo-basic-btn",
                                          attrs: {
                                            pressed:
                                              _vm.providerOrPractice ==
                                              "provider",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setProviderOrPracticeTotals(
                                                "provider"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Provider ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "ml-2 avo-basic-btn",
                                          attrs: {
                                            pressed:
                                              _vm.providerOrPractice ==
                                              "practice",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setProviderOrPracticeTotals(
                                                "practice"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Practice ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#010c14",
                        "font-size": "36px",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDollars(_vm.totalBillingValue, false)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.provider
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottomleft",
                              value: { variant: "dark" },
                              expression: "{ variant: 'dark' }",
                              modifiers: { hover: true, bottomleft: true },
                            },
                          ],
                          staticClass: "encounters-line",
                          attrs: {
                            title:
                              "Your Encounters / Total Practice Encounters",
                          },
                        },
                        [
                          _vm._v(
                            " Encounters: " +
                              _vm._s(_vm.formatNumber(_vm.totalEncounters)) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.provider
                    ? _c(
                        "div",
                        {
                          staticClass: "data-label",
                          staticStyle: { "font-size": "14px" },
                        },
                        [
                          _vm._v(
                            " Please select a provider to view billing summary "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.provider
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content mt-5 pr-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column mb-3" },
                            [
                              _c("div", { staticClass: "data-label" }, [
                                _vm._v("Total AR balance"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "small-dollar-amount" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDollars(
                                              _vm.totalBalance,
                                              false
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "billing-percentage" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getBillingBarPercentage(
                                              _vm.totalBalance
                                            )
                                          ) +
                                          "% "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticStyle: { width: "100%" } }, [
                                _c("div", {
                                  staticClass: "thin-bar-background",
                                }),
                                _c("div", {
                                  staticClass: "thin-bar-overlay",
                                  style: _vm.getBillingBarStyle("balance"),
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column mb-3" },
                            [
                              _c("div", { staticClass: "data-label" }, [
                                _vm._v("Total paid"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "small-dollar-amount" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDollars(
                                              _vm.totalPaid,
                                              false
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "billing-percentage" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getBillingBarPercentage(
                                              _vm.totalPaid
                                            )
                                          ) +
                                          "% "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticStyle: { width: "100%" } }, [
                                _c("div", {
                                  staticClass: "thin-bar-background",
                                }),
                                _c("div", {
                                  staticClass: "thin-bar-overlay",
                                  style: _vm.getBillingBarStyle("paid"),
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column mb-3" },
                            [
                              _c("div", { staticClass: "data-label" }, [
                                _vm._v("Total adjustments"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "small-dollar-amount" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDollars(
                                              _vm.totalAdjustments,
                                              false
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "billing-percentage" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getBillingBarPercentage(
                                              _vm.totalAdjustments
                                            )
                                          ) +
                                          "% "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticStyle: { width: "100%" } }, [
                                _c("div", {
                                  staticClass: "thin-bar-background",
                                }),
                                _c("div", {
                                  staticClass: "thin-bar-overlay",
                                  style: _vm.getBillingBarStyle("adjustments"),
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column mb-3" },
                            [
                              _c("div", { staticClass: "data-label" }, [
                                _vm._v("Total write-offs"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "small-dollar-amount" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDollars(
                                              _vm.totalWriteOffs,
                                              false
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "billing-percentage" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getBillingBarPercentage(
                                              _vm.totalWriteOffs
                                            )
                                          ) +
                                          "% "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticStyle: { width: "100%" } }, [
                                _c("div", {
                                  staticClass: "thin-bar-background",
                                }),
                                _c("div", {
                                  staticClass: "thin-bar-overlay",
                                  style: _vm.getBillingBarStyle("write-offs"),
                                }),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "b-col",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { cols: "8" },
                },
                [
                  _c(
                    "b-button-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2 avo-basic-btn",
                          attrs: {
                            pressed: _vm.chartDateRange == "year",
                            size: "sm",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setChartDateRange("ytd")
                            },
                          },
                        },
                        [_vm._v(" YTD ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2 avo-basic-btn",
                          attrs: {
                            pressed: _vm.chartDateRange == "quarter",
                            size: "sm",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setChartDateRange("quarter")
                            },
                          },
                        },
                        [_vm._v(" Current Quarter ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2 avo-basic-btn",
                          attrs: {
                            pressed: _vm.chartDateRange == "month",
                            size: "sm",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setChartDateRange("month")
                            },
                          },
                        },
                        [_vm._v(" Last Month ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "chart-container monthly-totals-chart" },
                    [
                      _c("monthly-totals-line-chart", {
                        attrs: { chartData: _vm.monthlyTotalsChartData },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }