var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-procedures-card shadow" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-4",
        },
        [
          _c("div", { staticClass: "card-header-small" }, [
            _vm._v(" " + _vm._s(_vm.headerText)),
          ]),
          _c(
            "div",
            { staticClass: "d-flex justify-content-end align-items-center" },
            _vm._l(["Claims", "CPT Codes"], function (value) {
              return _c(
                "div",
                {
                  key: value,
                  class: [
                    _vm.activeTab == value
                      ? "card-tab-active"
                      : "card-tab-inactive",
                    "mr-2",
                  ],
                  attrs: { role: "button" },
                  on: {
                    click: function ($event) {
                      _vm.activeTab = value
                    },
                  },
                },
                [_vm._v(" " + _vm._s(value) + " ")]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "b-overlay",
        { attrs: { show: _vm.topProceduresLoading, variant: "white" } },
        _vm._l(_vm.topProcedureSelection, function (item, index) {
          return _c(
            "b-row",
            { key: index, staticClass: "mb-3", attrs: { "align-v": "center" } },
            [
              item.code
                ? _c("b-col", { staticClass: "pr-0", attrs: { cols: "8" } }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c("div", { staticClass: "procedure-icon mr-3" }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card-header-small mr-3 code-text",
                                },
                                [_vm._v(_vm._s(item.code))]
                              ),
                              _c(
                                "div",
                                { staticClass: "avo-text-light-small" },
                                [_vm._v(_vm._s(item.description))]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              item.code
                ? _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      _c("div", { staticClass: "avo-header-text-med" }, [
                        _vm._v(" " + _vm._s(_vm.formatter(item.count)) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }