var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "widget-container" },
    [
      _c(
        "job-overlay",
        { attrs: { job: _vm.job }, on: { finished: _vm.onFinished } },
        [
          _c(
            "b-row",
            { staticClass: "m-0", attrs: { "align-h": "around" } },
            _vm._l(_vm.timeCardData, function (data) {
              return _c(
                "b-col",
                {
                  key: data.label,
                  class: "avg-pay-time-card " + data.gradient,
                },
                [
                  _c("div", { staticClass: "mb-3 widget-heading-text" }, [
                    _vm._v(" " + _vm._s(data.label) + " "),
                  ]),
                  _c(
                    "b-overlay",
                    { attrs: { variant: "transparent", show: _vm.loading } },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "widget-days-text",
                            staticStyle: { "font-size": "32px" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDecimal(data.value, "N/A")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "ml-2 mt-1 widget-days-text" },
                          [_vm._v("days")]
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "clock-img" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/" + data.clock) },
                    }),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }