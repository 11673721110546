var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-procedures-card shadow" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-4",
        },
        [
          _c("div", { staticClass: "card-header-small" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
        ]
      ),
      _c("donut-with-bar", {
        attrs: {
          "chart-data": _vm.topProceduresChartData,
          "data-loading": _vm.topProceduresLoading,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }