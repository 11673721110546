var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "greeting-card mb-5" }, [
    _c("div", { staticClass: "blob-1-img" }, [
      _c("img", { attrs: { src: require("@/assets/blob1.svg") } }),
    ]),
    _c("div", { staticClass: "blob-2-img" }, [
      _c("img", { attrs: { src: require("@/assets/blob2.svg") } }),
    ]),
    _c("div", { staticClass: "provider-hero-img" }, [
      _c("img", { attrs: { src: require("@/assets/provider-hero.svg") } }),
    ]),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-column justify-content-center h-100 ml-5 pl-4",
      },
      [
        _vm.provider
          ? _c("div", { staticClass: "bold-text-head" }, [
              _vm._v(
                "Hello, " +
                  _vm._s(_vm.provider.full_name_with_suffix || "provider") +
                  "!"
              ),
            ])
          : _c("div", { staticClass: "bold-text-head" }, [
              _vm._v("No provider selected"),
            ]),
        _vm.provider
          ? _c("div", { staticClass: "greeting-text-light mb-3" }, [
              _vm._v("Here's what's happening so far this month"),
            ])
          : _c("div", { staticClass: "greeting-text-light mb-3" }, [
              _vm._v(
                "To see a provider's billing totals for this month, please select a provider"
              ),
            ]),
        _c(
          "b-overlay",
          { attrs: { show: _vm.loading, variant: "transparent" } },
          [
            _c("div", { staticClass: "d-flex flex-row" }, [
              _c("div", { staticClass: "flex-column mr-4" }, [
                _c("div", { staticClass: "greeting-label-text" }, [
                  _vm._v("Total billed"),
                ]),
                _c("div", { staticClass: "stat-text-med" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatDollars(_vm.quickSummaryData.totalBilled, false)
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "flex-column mr-4" }, [
                _c("div", { staticClass: "greeting-label-text" }, [
                  _vm._v("Total encounters"),
                ]),
                _c("div", { staticClass: "stat-text-med" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatNumber(_vm.quickSummaryData.totalEncounters)
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "flex-column mr-4" }, [
                _c("div", { staticClass: "greeting-label-text" }, [
                  _vm._v("Total paid"),
                ]),
                _c("div", { staticClass: "stat-text-med" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatDollars(_vm.quickSummaryData.totalPaid, false)
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "flex-column" }, [
                _c("div", { staticClass: "greeting-label-text" }, [
                  _vm._v("Net Collection %"),
                ]),
                _c("div", { staticClass: "stat-text-med" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatPercent(
                        _vm.quickSummaryData.totalPaid /
                          _vm.quickSummaryData.totalBilled,
                        false
                      )
                    )
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }