var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "align-items-center" },
      [
        _c("vue-horizontal-calendar", {
          attrs: {
            lang: "en",
            choosedDatePos: "center",
            choosedItemColor: "var(--blue)",
            todayItemColor: "#E0EAF8",
            showBorderTop: false,
            swipeSpace: 7,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "p-3 py-4" }, [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("div", { staticClass: "bold-text-head" }, [
          _vm._v(
            " You have " +
              _vm._s(_vm.visits.length > 0 ? _vm.visits.length : "no") +
              " visits "
          ),
        ]),
        _c("div", { staticClass: "bold-text-head" }, [
          _vm._v("scheduled for today"),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "p-4 d-flex flex-column" },
      _vm._l(_vm.visits, function (visit) {
        return _c("div", { key: visit.visit_id, staticClass: "visit" }, [
          _c(
            "div",
            {
              staticClass:
                "\n          d-flex\n          flex-column\n          align-items-between\n          justify-content-between\n          p-1\n        ",
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                !visit.preauth.preauth_id
                  ? _c(
                      "div",
                      {
                        staticClass: "mr-1 shield-no-preauth d-flex",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "shield-minus", scale: "1.4" },
                        }),
                        _c("div", { staticClass: "ml-2" }, [
                          _vm._v("No Preauth"),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "mr-1 shield-approved",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "shield", scale: "1.4" },
                        }),
                      ],
                      1
                    ),
                _c("div", { staticClass: "d-flex flex-column" }, [
                  visit.preauth_cptcodes
                    ? _c(
                        "div",
                        _vm._l(
                          visit.preauth_cptcodes,
                          function (cptCode, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "var(--dark)",
                                      "font-size": "12px",
                                      "font-weight": "500",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(cptCode.code) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-1",
                                    staticStyle: {
                                      color: "var(--gray)",
                                      "font-size": "11px",
                                    },
                                  },
                                  [_vm._v(" - " + _vm._s(cptCode.short) + " ")]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c(
                        "div",
                        _vm._l(
                          visit.preauth_icdcodes,
                          function (icdCode, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "var(--dark)",
                                      "font-size": "12px",
                                      "font-weight": "500",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(icdCode.code) + " ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-1",
                                    staticStyle: {
                                      color: "var(--gray)",
                                      "font-size": "11px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " - " + _vm._s(icdCode.description) + " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column pl-2 my-2 justify-content-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "var(--dark)",
                        "font-size": "12px",
                      },
                    },
                    [_vm._v(" " + _vm._s(visit.patient.full_name) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "var(--gray)",
                        "font-size": "12px",
                      },
                    },
                    [
                      _vm._v(
                        " Acct# " +
                          _vm._s(visit.patient.account_no || "N/A") +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "var(--gray)",
                        "font-size": "12px",
                      },
                    },
                    [_vm._v(" " + _vm._s(visit.location_name) + " ")]
                  ),
                ]
              ),
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("b", [
                  _vm._v(_vm._s(_vm.formatTime(visit.start_time)) + " "),
                ]),
                visit.end_time
                  ? _c("b", { staticClass: "ml-1" }, [
                      _vm._v(" - " + _vm._s(_vm.formatTime(visit.end_time))),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }