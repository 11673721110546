import { render, staticRenderFns } from "./TopDiagnosesWidget.vue?vue&type=template&id=74e4760a&scoped=true&"
import script from "./TopDiagnosesWidget.vue?vue&type=script&lang=js&"
export * from "./TopDiagnosesWidget.vue?vue&type=script&lang=js&"
import style0 from "./TopDiagnosesWidget.vue?vue&type=style&index=0&id=74e4760a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e4760a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ethan/Documents/Github/avosina/web/avosina-claims/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74e4760a')) {
      api.createRecord('74e4760a', component.options)
    } else {
      api.reload('74e4760a', component.options)
    }
    module.hot.accept("./TopDiagnosesWidget.vue?vue&type=template&id=74e4760a&scoped=true&", function () {
      api.rerender('74e4760a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/providerPortal/reportWidgets/TopDiagnosesWidget.vue"
export default component.exports