var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm.hasPermission("see_other_provider_metrics")
              ? _c(
                  "div",
                  { staticClass: "d-flex pl-4 transition" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "View Provider Dashboard as provider:",
                        },
                      },
                      [
                        _c(
                          "multiselect",
                          {
                            attrs: {
                              openDirection: "bottom",
                              placeholder: "Search by provider name",
                              "custom-label": _vm.providerLabel,
                              "track-by": "provider_id",
                              options: _vm.selectableProviders,
                              loading: _vm.providersLoading,
                              "internal-search": false,
                              "show-labels": false,
                              showNoOptions: true,
                              "allow-empty": true,
                            },
                            on: { "search-change": _vm.debounceProviders },
                            model: {
                              value: _vm.multiselectSelectedProvider,
                              callback: function ($$v) {
                                _vm.multiselectSelectedProvider = $$v
                              },
                              expression: "multiselectSelectedProvider",
                            },
                          },
                          [
                            _c("template", { slot: "noResult" }, [
                              _vm._v(" No providers matched your search "),
                            ]),
                            _c("template", { slot: "noOptions" }, [
                              _vm._v(" Start typing a provider name... "),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-3" },
        [
          _c(
            "b-col",
            {
              staticClass: "transition",
              attrs: { cols: _vm.scheduleOpen ? 8 : 11 },
            },
            [
              _c("provider-greeting-widget", {
                attrs: {
                  provider: _vm.selectedProvider,
                  practice: _vm.user_practice,
                },
              }),
              _c("div", { staticClass: "avo-header-text-med mb-3" }, [
                _vm._v(" Billing summary "),
              ]),
              _c("billing-summary-widget", {
                attrs: {
                  provider: _vm.selectedProvider,
                  practice: _vm.user_practice,
                },
              }),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("average-time-widget", {
                        attrs: {
                          provider: _vm.selectedProvider,
                          practice: _vm.user_practice,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "active-ar-card shadow mb-3" },
                      [
                        _c("average-ar-widget", {
                          attrs: { provider: _vm.selectedProvider },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-5", attrs: { lg: "6" } },
                    [
                      _c("top-procedures-widget", {
                        attrs: { provider: _vm.selectedProvider },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-5", attrs: { lg: "6" } },
                    [
                      _c("top-diagnoses-widget", {
                        attrs: { provider: _vm.selectedProvider },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-5", attrs: { cols: "12" } },
                    [
                      _c("top-office-encounters-widget", {
                        attrs: { provider: _vm.selectedProvider },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "mb-5", attrs: { lg: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "active-ar-chart-card shadow" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center mb-4",
                          },
                          [
                            _c("div", { staticClass: "card-header-small" }, [
                              _vm._v("Active A/R by age"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.ARByAgeFilterSelection == "All"
                                        ? "card-tab-active"
                                        : "card-tab-inactive",
                                      "mr-2",
                                    ],
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.ARByAgeFilterSelection = "All"
                                      },
                                    },
                                  },
                                  [_vm._v("All")]
                                ),
                                _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.ARByAgeFilterSelection == "Insurance"
                                        ? "card-tab-active"
                                        : "card-tab-inactive",
                                      "mr-2",
                                    ],
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.ARByAgeFilterSelection = "Insurance"
                                      },
                                    },
                                  },
                                  [_vm._v("Insurance")]
                                ),
                                _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.ARByAgeFilterSelection == "Patient"
                                        ? "card-tab-active"
                                        : "card-tab-inactive",
                                    ],
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.ARByAgeFilterSelection = "Patient"
                                      },
                                    },
                                  },
                                  [_vm._v("Patient")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm.ARByAgeLoading
                          ? _c(
                              "div",
                              { staticClass: "spinner-container" },
                              [_c("b-spinner")],
                              1
                            )
                          : _vm._e(),
                        _c("active-ar-bar-chart", {
                          attrs: {
                            chartData: _vm.activeARBarChartData,
                            styles: { height: "100%", position: "relative" },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "avo-header-text-med mb-3" }, [
                _vm._v(" Claims Summary "),
              ]),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "rejected-claims-card mb-5" },
                      [
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              variant: "transparent",
                              show: _vm.rejectionDenialsLoading,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between align-items-center mb-3",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          _vm.rejectionDenialSelection ==
                                          "Rejections"
                                            ? "card-tab-active"
                                            : "card-tab-inactive",
                                          "mr-2",
                                        ],
                                        attrs: { role: "button" },
                                        on: {
                                          click: function ($event) {
                                            _vm.rejectionDenialSelection =
                                              "Rejections"
                                          },
                                        },
                                      },
                                      [_vm._v("Rejections")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          _vm.rejectionDenialSelection ==
                                          "Denials"
                                            ? "card-tab-active"
                                            : "card-tab-inactive",
                                        ],
                                        attrs: { role: "button" },
                                        on: {
                                          click: function ($event) {
                                            _vm.rejectionDenialSelection =
                                              "Denials"
                                          },
                                        },
                                      },
                                      [_vm._v("Denials")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center mb-3" },
                              [
                                _c("img", {
                                  staticClass: "rejected-claim-img",
                                  attrs: {
                                    src: require("@/assets/rejected-claim-icon.svg"),
                                  },
                                }),
                                _vm.rejectionDenialSelection == "Rejections" &&
                                _vm.rejectionDenialsData
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              "font-weight": "500",
                                              color: "#43464A",
                                              "margin-top": "4px",
                                            },
                                          },
                                          [_vm._v("Rejected claims")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "stat-text-med" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  _vm.rejectionDenialsData
                                                    .rejection_count || 0
                                                ).toLocaleString()
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.rejectionDenialSelection == "Denials" &&
                                    _vm.rejectionDenialsData
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              "font-weight": "500",
                                              color: "#43464A",
                                              "margin-top": "4px",
                                            },
                                          },
                                          [_vm._v("Denied claims")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "stat-text-med" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                Number(
                                                  _vm.rejectionDenialsData
                                                    .denial_count || 0
                                                ).toLocaleString()
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("div", { staticClass: "top-reasons-box" }, [
                              _vm.rejectionDenialSelection == "Rejections" &&
                              _vm.rejectionDenialsData
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-2",
                                        staticStyle: { "font-weight": "500" },
                                      },
                                      [_vm._v("Top rejection reasons")]
                                    ),
                                    _vm.rejectionDenialsData
                                      .rejected_claims_by_type.length > 0
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            _vm.rejectionDenialsData
                                              .rejected_claims_by_type,
                                            function (reason, index) {
                                              return _c(
                                                "b-row",
                                                { key: index },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            reason.clearinghouse_error_type
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            reason.claim_count
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "500",
                                                      },
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getRejectionTypePercentage(
                                                              reason.claim_count
                                                            )
                                                          ) +
                                                          "% "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "no-data-text my-3" },
                                          [_vm._v(" No rejected claims ")]
                                        ),
                                  ])
                                : _vm.rejectionDenialSelection == "Denials" &&
                                  _vm.rejectionDenialsData
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-2",
                                        staticStyle: { "font-weight": "500" },
                                      },
                                      [_vm._v("Top denial reasons")]
                                    ),
                                    _vm.rejectionDenialsData
                                      .denied_claims_by_type.length > 0
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            _vm.rejectionDenialsData
                                              .denied_claims_by_type,
                                            function (reason, index) {
                                              return _c(
                                                "b-row",
                                                { key: index },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            reason.denial_type
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            reason.claim_count
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "500",
                                                      },
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getDenialTypePercentage(
                                                              reason.claim_count
                                                            )
                                                          ) +
                                                          "% "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "no-data-text my-3" },
                                          [_vm._v(" No denied claims ")]
                                        ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "pr-0 transition",
              attrs: { cols: _vm.scheduleOpen ? 4 : 1 },
            },
            [
              _c("div", { staticClass: "right-column p-4" }, [
                _c(
                  "div",
                  { staticClass: "expand-nav-div" },
                  [
                    _c(
                      "b-iconstack",
                      {
                        staticClass: "cursor-pointer mb-3",
                        on: {
                          click: function ($event) {
                            _vm.scheduleOpen = !_vm.scheduleOpen
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "circle-fill",
                            variant: "primary",
                            scale: "2",
                          },
                        }),
                        !_vm.scheduleOpen
                          ? _c("b-icon", {
                              attrs: { variant: "light", icon: "chevron-left" },
                            })
                          : _c("b-icon", {
                              attrs: {
                                variant: "light",
                                icon: "chevron-right",
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "avo-header-text-med mb-3 transition",
                    class: _vm.scheduleOpen ? "" : "rotate",
                  },
                  [_vm._v(" Schedule ")]
                ),
                _c(
                  "div",
                  [
                    _c("provider-schedule", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.scheduleOpen,
                          expression: "scheduleOpen",
                        },
                      ],
                      attrs: { provider: _vm.selectedProvider },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }