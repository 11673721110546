var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        { attrs: { variant: "transparent", show: _vm.loading } },
        [
          _c(
            "b-overlay",
            {
              attrs: { variant: "transparent", show: _vm.show },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "report-loading-msg" },
                        [
                          _vm.showSpinner
                            ? _c("b-spinner", { attrs: { size: "lg" } })
                            : _vm._e(),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.message)),
                          _c("br"),
                          _vm.showStatus
                            ? _c("div", { staticClass: "status-line" }, [
                                _vm._v(
                                  " Status: " +
                                    _vm._s(_vm.jobStatusRendered) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }